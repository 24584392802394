<template>
  <div class="general-data">
    <v-form v-model="valid" ref="form">
      <div class="tway-header-2 mb-4">
        Equipo
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Describe en breves palabras el equipo encargado para el proyecto (cantidad de personal, cargos, etc.)</span
          >
        </v-tooltip>
      </div>
      <v-textarea
        v-model="formData.teamDescription"
        :rules="[required('Equipo'), validateMaxLength(250)]"
        counter
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Escribe una descripción general"
        outlined
        no-resize
      />
      <div class="tway-header-2 mb-4">
        Características detalladas del Equipo
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >CV de los profesionales involucrados, asignación de roles o especialidad y porcentaje de esfuerzo
          </span>
        </v-tooltip>
      </div>
      <v-file-input
        multiple
        v-model="formData.teamCharacteristics"
        append-icon="mdi-paperclip"
        :prepend-icon="null"
        :rules="[
          () => (formData.teamCharacteristics ? true : 'Campo Características detalladas del Equipo es requerido'),
        ]"
        accept="application/pdf"
        class="mb-10 roboto"
        outlined
        color="rgb(150, 82, 223)"
        placeholder="Agrega uno o varios archivos aquí"
        :value="formData.teamCharacteristics"
        @change="(file) => upload(file, 'teamCharacteristics')"
      />
      <div class="tway-header-2 mb-4">
        Anexos
        <v-tooltip bottom max-width="20em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Puedes agregar archivos anexos relacionados a la postulación.</span>
        </v-tooltip>
      </div>
      <v-file-input
        multiple
        append-icon="mdi-paperclip"
        :prepend-icon="null"
        accept="application/pdf"
        class="mb-10 roboto"
        outlined
        color="rgb(150, 82, 223)"
        placeholder="Agrega uno o varios archivos aquí"
        :value="formData.aditionalAttachments"
        @change="(file) => upload(file, 'aditionalAttachments')"
      />
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";

export default {
  mounted() {
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = stepData.data;
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  updated() {
    let { teamCharacteristics, aditionalAttachments } = this.formData;
    if ((this.valid && teamCharacteristics) || (this.valid && teamCharacteristics && aditionalAttachments)) {
      this.setComplete(this.stepIndex);
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
    } else {
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
      this.setInProgress(this.stepIndex);
    }
  },
  name: "GeneralData",
  data() {
    return {
      stepIndex: 2,
      valid: false,

      formData: {
        teamDescription: "",
        teamCharacteristics: null,
        aditionalAttachments: null,
      },
    };
  },
  methods: {
    ...mapActions("applyForProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    formatCounter: (max) => (text) => max - text.length,
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateMaxLength: (max) => ValidatorsService.validateMaxLength(max),
    uploadEconomicalOffer(file) {
      this.formData.economicalOffer = file;
    },
    uploadAnexed(file) {
      this.formData.aditionalAttachment = file;
    },
    upload(file, key) {
      this.formData[key] = file;
      let { teamCharacteristics, attachments } = this.formData;
      if (this.valid && teamCharacteristics && attachments) {
        this.setComplete(this.stepIndex);
        this.setStepData({
          index: this.stepIndex,
          data: { ...this.formData },
        });
      } else {
        this.setInProgress(this.stepIndex);
      }
    },
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "applyForProject/getStepIsComplete",
      getStep: "applyForProject/getStep",
    }),
  },
};
</script>

<style scoped>
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}
.v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}
.general-data .v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}
.general-data >>> .v-input__icon {
  transform: rotate(-30deg) !important;
}
</style>
